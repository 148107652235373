/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
@import "intl-tel-input/build/css/intlTelInput.css";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");

html {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
  }
}

:root {
  --primary-color: #ffcd00;

  * {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .wrapper_sec {
    padding: 100px 0px;
  }

  ul,
  li,
  ol {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  h2,
  .mrcrd_hd h1 {
    font-size: 18px;
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .p20 {
    padding: 20px;
  }

  .conta_iner {
    width: 95%;
    margin: 0 auto;
    padding: 0;
    position: relative;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000 !important;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  .add_ussr .iti.iti--allow-dropdown,
  .add_ussr input,
  .add_ussr input#phone,
  .add_ussr ngx-intl-tel-input,
  .add_ussr ngx-intl-tel-input#phone {
    width: 100%;
  }

  // card color scss strt

  .card_flex .hvrcursor:nth-child(1) .card-body {
    background: #8adb36bf;
  }

  .card_flex .hvrcursor:nth-child(2) .card-body {
    background: #ff816f;
  }

  .card_flex .hvrcursor:nth-child(3) .card-body {
    background: #90c8fc;
  }

  .card_flex .hvrcursor:nth-child(4) .card-body {
    background: #76c5b2;
  }
  .btn-cancelled {
    color: red;
  }
  .modal-backdrop {
    display: none;
  }

  .card_flex .hvrcursor:nth-child(5) .card-body {
    background: #0085cb;
  }
  .card_flex .hvrcursor:nth-child(6) .card-body {
    background: #54c6e5;
  }

  .card_flex .hvrcursor:nth-child(7) .card-body {
    background: #8d6f4abf;
  }

  .card_flex .hvrcursor:nth-child(8) .card-body {
    background: #00398edb;
  }

  .card_flex .hvrcursor:nth-child(9) .card-body {
    background: #0085cb;
  }

  .card_flex .hvrcursor:nth-child(10) .card-body {
    background: #54c6e5;
  }

  .card_flex .hvrcursor:nth-child(11) .card-body {
    background: #8d6f4abf;
  }

  .card_flex .hvrcursor:nth-child(12) .card-body {
    background: #00398edb;
  }

  .card_flex .hvrcursor:nth-child(13) .card-body {
    background: #ffffff;
  }

  .card_flex .hvrcursor:nth-child(14) .card-body {
    background: #ffffff;
  }
  // end

  #wrapper #content-wrapper {
    background-color: #f8f8f8;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
  }

  /**common class**/
  html {
    background: #fbfbfb;
  }

  .flex {
    display: flex;
    align-items: stretch;
  }
}

.login_right,
.login_formss {
  width: 49%;
}

.login-inner-body {
  display: flex;
  justify-content: space-between;
}

.login_formss .text-left {
  width: 60%;
}
.login_formss .text-left-sgn {
  width: 70%;
}
agm-map.sebm-google-map-container {
  height: 150px;
}
.outer_uploadsshdf p {
  padding: 4px;
  color: #828282;
  margin-bottom: 0 !important;
  z-index: 2;
  position: absolute;
  font-weight: 500;
  justify-content: space-between;
  display: flex;
  background: #fff;
  width: 100%;
}
.bigholderhor {
  height: 265px;
}
.image_placeholder_showup_file {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.image_placeholder_showup_file input {
  width: 100%;
  opacity: 0;
  height: 100%;
}

.login_formss {
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
  position: relative;
  i.fas.fa-solid.fa-arrow-left {
    position: absolute;
    top: 20px;
    left: 19%;
    width: 40px;
    font-size: 18px;
    color: #000;
    padding: 10px;
    border-radius: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 5px 2px #00000014;
    transition: transform 0.6s;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.usr_profile_conts {
  padding: 20px;
}

.dminy_status {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.dminy_status .common-btn {
  width: 77px;
}
.login_formss .iti.iti--allow-dropdown {
  margin-bottom: 15px;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-bottom: 2px;
  font-size: 14px;
  color: #f55353;
}
.cdk-overlay-container {
  z-index: 10000;
}
.swal2-container{
  z-index: 11111 !important;
}
.topbar {
  position: fixed;
  right: 0;
  width: 100%;
  left: 0;
  margin: 0px !important;
  z-index: 11;
  top: 0;
  box-shadow: 0px 0px 2px 3px #0000000f;
}

/*color and Background color Variables*/

$primary_color: var(--primary-color);
$primary_backgroundcolor: #4e73df;
$secondary_color: #002033;
$secondary_backgroundcolor: #002033;
$btn_primary_color: #002033;
$btn_primary_color: #002033;
$btn_primary_backcolor: #002033;
$btn_primary_backcolor: #002033;
$btn_primary_whitecolor: #fff;
$btn_secondary_whitecolor: #eee;
$btn_primary_whitebackcolor: #fff;
$btn_secondary_whitebackcolor: #eee;
$text_white_color: #fff;
$text_primary_color: #002033;
$text_secondary_color: #002033;
$text_white_dullcolor: #e3e3e3;
$text_succes_color: #155724;
$text_succes_backcolor: #d4edda;
$text_danger_color: #721c24;
$text_danger_backcolor: #f8d7da;
$text_sidebar_textcolor: #3e9900;
$text_sidebar_textbackcolor: #3e99001f;
$text_sidebar_inactivetextcolor: #969ba0;
$btn-primary: #39801d;
$btn-primarycolor: #fff;

.product_action button.btn.btn-primary {
  background-color: #2f4cdd !important;
  border-color: #4e73df !important;
}

.product_action button.btn-primary:focus,
.product_action button.btn-primary.focus,
.product_action button.btn-primary:active {
  color: #fff;
  background-color: #2f4cdd !important;
  border-color: #4e73df !important;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
  border: unset;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary-color);
}

.btn-danger {
  color: #fff;
}
.revenue_mng {
  margin: 100px 0px;
}
.notflistit h4 {
  font-size: 16px;
  font-weight: 500;
  strong {
    font-size: 16px;
    font-weight: 500;
    color: #00000085;
  }
}
button.import_icon.imex_ddwn.btn.btn-primary {
  position: relative;
}

.text-rightForgot {
  text-align: right;
  padding: 10px 0;
  color: $primary_color;
}

a {
  color: $primary_color;
  cursor: pointer;
}

.common-btn {
  line-height: normal;
  border-radius: 6px;
  border: unset;
  font-size: 16px;
}
.modal_cancel {
  position: relative;
  .cancel_btn {
    position: absolute;
    right: 60px;
    top: 15px;
  }
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #3e9900 !important;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #3e990026 !important;
}
.ml-auto figure svg {
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
}

.rigefnevs {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch.checked {
  background: #848484;
}
.pac-container {
  z-index: 10000 !important;
}

button.btn.btn_item {
  color: #fff;
  background-color: #f600ff;
}

.btn-primary_border {
  background: #cccccc7a;
  color: $primary_color;
  border: 2px solid $primary_color;
}

.table-responsive h4 {
  padding-bottom: 20px;
}

.switch small {
  background: #000 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: $primary_color;
  border-color: $primary_color;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: $primary_color;
  border-color: $primary_color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary_color;
  border-color: $primary_color;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  border-color: $primary_color;
}

.cat_ui {
  overflow: hidden;
  overflow-x: auto;
}

.catui_row {
  display: flex;
  justify-content: flex-start;
  width: 1400px;
}

.selected {
  background-color: $primary_color;
  color: white !important;
}

.category_layout {
  margin-bottom: 30px;
}

.category_layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
}

span.psearch_icon {
  margin-right: 10px;
}

.delete-span-cat {
  padding-left: 10px;
}

.p-search_btn {
  position: absolute;
  width: 88%;
  opacity: 0;
  z-index: -9999;
  top: 15px;
}

.onpfline input {
  border: none !important;
}

.btn_ellipse i:before {
  font-size: 15px;
  margin-left: 7px;
}

.p-search_btn input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  color: #000;
  padding: 6px;
  position: relative;
  padding-left: 13px;
}

.p-search_btn i {
  position: absolute;
  right: 8px;
  top: 6px;
}

.bottom_cate_data {
  height: 380px;
  overflow-y: auto;
}

.cate_data {
  border: 1px solid #ddd;
  padding: 7px 14px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.category_layout {
  margin-bottom: 30px;
}

.category_layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
}

.catdata_name p {
  font-size: 14px;
  color: #282828;
  font-weight: 400;
}

.combo_action span {
  position: relative;
  cursor: pointer;
}

.combo_action i {
  color: #777070;
}

.combo_action i:before {
  margin-left: 0;
  font-size: 14px;
  position: relative;
  left: 11px;
}

.cate_data .drpdwn_action {
  width: 170px;
  top: 25px;
  left: inherit;
  right: -2px;
}

.category_tabs .ngx-pagination {
  margin-bottom: 0 !important;
}

.addbtn_action i:before {
  font-size: 14px;
  margin-left: 0;
  color: var(--primary-color);
}

.product_action span {
  font-size: 15px;
}

.switch {
  height: 22px !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  margin: 0 auto;
}

.switch small {
  width: 26px !important;
  height: 26px !important;
  top: unset;
}

.switch small {
  right: calc(100% - 20px) !important;
}

.switch.checked small {
  right: 0 !important;
}

.btn-margin-e {
  margin-top: -32px;
}

.pd-de {
  padding-top: 8px;
  padding-left: 0;
  margin-bottom: 0px;
  margin-top: 8px;
}

input.checkbox {
  display: inline-block;
}

.pd-de input {
  padding: 10px;
}

.table-responsive input {
  -webkit-appearance: checkbox !important;
}

.card-body.table-body {
  height: 70vh;
  overflow: scroll;
}

.user_filter.single-filter i {
  display: none;
}

.pd-de {
  padding-top: 8px;
  padding-left: 0;
  margin-bottom: 0px;
  margin-top: 8px;
}

input.checkbox {
  display: inline-block;
}
.iti--allow-dropdown .iti__flag-container {
  right: unset;
  left: 0px;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0px;
  padding: 1px;
}
.iti input {
  margin-bottom: 15px !important;
}

.signup.sisgnup {
  text-align: left;
  padding: 10px 0;
}

.slecttype mat-radio-group.mat-radio-group.ng-pristine.ng-valid.ng-touched {
  display: flex;
  flex-direction: column;
}

.slecttype mat-radio-button {
  margin-bottom: 20px;
}

.login_formss.losgin_formss {
  width: 100%;
  height: auto;
}

.losgin_formss .text-left {
  width: 100%;
}

.pd-de input {
  padding: 10px;
}

.table-responsive input {
  -webkit-appearance: checkbox !important;
}

.custom-select:focus {
  box-shadow: none !important;
}

.user_filter.single-filter.for-search i {
  display: block;
  position: absolute;
  top: 13px;
  right: 12px;
}

.for-search {
  position: relative;
}

.card-sample {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.single-cards {
  background: #fff;
  box-shadow: 0 4px 16px #00000008;
  padding: 0px;
  border-radius: 10px;
  width: 24%;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.blccard-img img {
  -o-object-fit: cover;
  object-fit: cover;
}

.blccard-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blccard-content h3 {
  color: #404040;
  font-size: 18px;
  font-weight: 500;
}

.blccard-content p {
  color: #000;
  font-size: 14px;
  margin-top: 6px;
}

.btn-block {
  width: auto !important;
}

.btn-forgot-password {
  cursor: pointer;
}

body {
  background: transparent !important;
}

.add_edit {
  width: 20%;
  margin: 0 auto;
  padding-top: 30px;
}

.right_login {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub_main_heading h2 {
  font-size: 50px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  color: #30373f;
}

.available-items li {
  padding: 6px 0 !important;
}

.note-onbord {
  background: #c6c6c6;
  padding: 19px;
  font-size: 12px;
  margin-top: 40px;
  color: #1f1f1f;
  border-radius: 10px;
}

.sub_main_heading p {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #949ea7;
  margin-top: 10px;
  margin-bottom: 20px;
}

.slecttype input {
  border: none !important;
}

.slecttype label.mat-radio-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.slecttype .mat-radio-label-content {
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 0;
  font-weight: bold;
}

.sub_main_heading h3 {
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #3b3b3bcc;
  margin: 10px 0px 20px 0px;
}

.login_formss .form-group i {
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 21px;
  color: #000;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.login_formss .form-group {
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.date-filter-wrap input {
  border: none !important;
}

.ryt {
  display: flex;
  width: 30%;
  justify-content: flex-end;
}
.col-md-6.high {
  height: 91px;
}

.ryt .common-btn {
  padding: 11px 0;
}
.store_setting select.form-control {
  width: 75%;
  margin: auto;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: normal;
  padding: 10px 10px;
}

ngx-select-dropdown.form-control-user {
  width: 100%;
}

.otp_boxes .wrapper {
  display: flex;
  flex-direction: row;
}

.otp_boxes .wrapper input {
  width: 24% !important;
}

/* You can add global styles to this file, and also import other style files */

/* Hide the browser's default checkbox */

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.col-card figure svg {
  width: 35px;
  height: 35px;
}
/* On mouse-over, add a grey background color */

// .new-chc:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.new-chc input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.new-chc .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.pd-de .row {
  justify-content: flex-end;
}
.btnspn button {
  background: transparent;
  padding: 5px 5px;
  width: 40px;
  border-radius: 5px 6px 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 3px 0px #00000091;
  span {
    font-size: 22px;
    color: #000;
    opacity: unset !important;
    font-weight: 600;
  }
}

.ad-btnn {
  width: 100%;
  text-align: right;
}

.pd-de {
  width: 100%;
  text-align: right;
}

.tittle_outer .common-btn {
  padding: 5px 10px;
  // margin-right: 5px;
}

.pd-invupdate {
  width: 80px;
}

/*-.user_add_button button.btn.btn-primary {
  width: 80px;
}*/

.user_add_button {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.catbtn_ui {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 6px;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
}

.modal-title {
  color: #000;
  font-weight: bold;
  padding: 16px;
}

a.dropdown-item i {
  font-size: 18px;
  position: relative;
  top: 1px;
}

a.navbar-brand {
  padding: 0px 0px;
  color: #fff !important;
  font-size: 18px;
  margin-right: 29px;
  background-color: #fff;
}

.dropdown-menu {
  padding: 14px;
}

.modal-body {
  color: #3c3c3c;
  padding: 20px;
  font-weight: 400;
}

.modal-footer {
  padding: 20px;
}

.topbar .topbar-divider {
  margin: auto 0.1rem auto 0.5rem;
}

.sidebar-brand-icon {
  height: 50px;
}

.sidebar-brand-icon img {
  height: 100%;
  max-width: 150px;
}

.filters_outer {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.btn_grp.d-flex {
  gap: 5px;
}

.user_serch_btn {
  position: relative;
  .d_Cross {
    position: absolute;
    right: 14px;
    top: 6px;
    transform: translate(-50%);
    height: 11px;
    width: 11px;
  }
}

.mat-dialog-title {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}

.up_file {
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  margin-top: 0;
  padding: 50px;
}

.user_imgcover img {
  width: 100%;
}

.user_img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  background: #efefef;
  bottom: 40px;
  left: 40px;
}

.user_imgcover .user_profile_edit {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.mat-select {
  text-align: left;
}

.new-chc {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

label.new-chc input {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.acticn ul li {
  margin-right: 10px;
}

.scllst_bdy {
  overflow-x: auto;
}

.up_file img {
  width: 36px;
  margin-right: 13px;
}

.up_file input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.up_file i.fas.fa-edit {
  color: #fff;
  font-size: 19px;
}

.usr_product_img {
  width: 100px;
  height: 100px;
  background: #f9f9f9;
  border-radius: 5px;
}
.outeadd_resurantin {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

// Start Rating

.usr_product_imgou.s_image {
  display: flex;
}

.notflist {
  width: 100%;
  display: flex;
}
.cpro_heading {
  font-size: 22px;
  font-weight: 500;
  color: #0000008c;
}

.notflist ul {
  display: flex;
  flex-direction: column;
}

.notflist ul li {
  width: 100%;
  margin: 0 0 20px 0;
}

.inot i {
  color: #b63f34;
  font-size: 20px;
  margin: 0;
}

.inot {
  margin-right: 20px;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 100%;
}

.notsid i {
  font-size: 25px;
}

.orrddtor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notflist {
  margin: 20px 0;
}

.notflist ul {
  width: 100%;
}
.notflistit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

.notflistit h2 {
  font-size: 24px;
  font-weight: 200;
}

.ratlis,
.geofence_btn button {
  margin-left: 10px;
}
.amount span {
  margin-left: 6px;
}

.ratlis .stl i {
  font-size: 30px;

  margin-right: 10px;
}

.ratlis p {
  font-size: 20px;
  font-weight: 100;
  padding: 6px 0px;
}

.ralstl {
  display: flex;
  background-color: var(--primary-color) !important;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
}

.prpic {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 100%;
  overflow: hidden;
}

.prpic img {
  width: 100%;
  height: 100%;
}

.ratlis h2 {
  font-size: 18px;
  margin-bottom: 18px;
}

.avrat {
  margin-bottom: 20px;
  margin-top: 20px;
}
.avrat ul {
  padding: 0px 10px;
}
.sub_rat h1 {
  font-size: 116px;
  color: #0c1f00;
}

.rwlis h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.rlialstl {
  display: flex;
}

.rlialstl i {
  color: var(--primary-color);
  margin-right: 10px;
}

.datesrp {
  color: #ababab;
}

.rwlis {
  width: 100%;

  background-color: #fff;
  padding: 0px 10px;
  border: 0px solid transparent;
  border-radius: 10px;
  box-shadow: 0 4px 16px #00000008;
}

.stl i {
  font-size: 19px;
  color: var(--primary-color);
}

.range {
  width: 86%;
}

.avrat li {
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
}

.avrat li span {
  font-size: 18px;
}

.ussubs_rat {
  flex-direction: column;
  width: 60%;
  margin: auto;
  text-align: center;
  align-items: center;
  display: flex;
}

.rwlsis h2 {
  font-size: 18px;
  color: black;
}

.ralstl i.fas {
  font-size: 18px;
  color: #ffffff;
  padding: 2px 10px;
}

.sub_rat .ussubs_rat h2 {
  font-size: 92px;
  color: var(--primary-color) !important;
  margin-bottom: 0;
}

.usorrtor p {
  margin: 10px 0;
}

.usrwlis {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.usratlis .stl i {
  font-size: 18px;
  color: #ffffff;
  padding: 2px 10px;
}

.range.excellent .progress-bar {
  background: #52ad52;
}

.range.good .progress-bar {
  background: #a4d131;
}

.range.average .progress-bar {
  background: #f7e642;
}

.range.below_average .progress-bar {
  background: #fba528;
}

.range.poor .progress-bar {
  background: #ee3918;
}

.revlist li {
  margin-bottom: 15px;
}

.sub_rat {
  margin: 20px 0 0;
}

// end Rating

.user_profile_edit span {
  display: block;
}

.up_file span {
  margin-bottom: 0px;
  margin-top: 0rem;
}

.user_img img {
  border: 1px solid #ddd;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df;
}

.border-bottom-primary {
  border-bottom: 0.25rem solid #4e73df;
}

.border-left-secondary {
  border-left: 0.25rem solid #858796;
}

.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a;
}

.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc;
}

.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e;
}

.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b;
}

.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b;
}

.border-left-light {
  border-left: 0.25rem solid #f8f9fc;
}

.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc;
}

.border-left-dark {
  border-left: 0.25rem solid #5a5c69;
}

.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69;
}
.input_tel_dta.form-control {
  padding: 0;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-primary {
  color: #4e73df;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #224abe;
}

.text-secondary {
  color: #858796;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #60616f;
}

.text-success {
  color: #1cc88a;
}

a.text-success:hover,
a.text-success:focus {
  color: #13855c;
}

.text-info {
  color: #36b9cc;
}

a.text-info:hover,
a.text-info:focus {
  color: #258391;
}

.text-warning {
  color: #f6c23e;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #dda20a;
}

.text-danger {
  color: #e74a3b;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #be2617;
}

.text-light {
  color: #f8f9fc;
}

a.text-light:hover,
a.text-light:focus {
  color: #c2cbe5;
}

.text-dark {
  color: #5a5c69;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #373840;
}

.text-body {
  color: #858796;
}

.text-muted {
  color: #858796;
}

a.dropdown-item i {
  color: $primary_color;
}

/*color and Background color Variables end*/

/**common class end**/

/**sidebar css**/

.bg-gradient-primary {
  background-color: $primary_backgroundcolor;
  height: 100% !important;
}

.inner_nav {
  display: flex;
  justify-content: space-between;

  margin: 0px auto;
  position: relative;
  z-index: 1000000000;
  align-items: center;
}

.sidebar {
  width: 6.5rem;
  min-height: 100vh;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar-dark .nav-item.active .nav-link {
  background: #46992414;
  color: $text_sidebar_textcolor;
  padding-right: 0;
  width: 100%;
}

li.nav-item.active a span {
  font-weight: 500;
  color: $text_sidebar_textcolor;
}

.error1 {
  color: #fff;
}

.cont1 label.star {
  font-size: 17px !important;
  transform: translateY(-5px);
}

.sales_person table td,
th {
  padding: 20px 13px;
}

.error1 {
  color: #fff;
}

button.assign_to_driver {
  background: linear-gradient(45deg, #4e73df, #4e73df) !important;
}

button.map_view {
  background-color: #ffb64d;
}

button.accept {
  background-color: #59e0c5;
  margin-right: 12px;
}

td select {
  border: 1px solid #bbbb;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 5px;
}

td a.business_detail {
  padding: 6px 9px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
  text-transform: capitalize;
  color: #fff;
  background: #000000;
  border: unset;
}

.table_right figure {
  display: none;
}

.drier_img img {
  width: 70px;
  border-radius: 50%;
  height: 70px;
}

.table-wrap {
  overflow: scroll;
}

.drier_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table {
  overflow-x: scroll;
  width: 100%;
}

.admin_table .table th:first-child {
  width: 30px !important;
}

.admin_table .table th:nth-child(2) {
  width: 230px;
}

.drier_innerinfor {
  width: 80%;
  text-align: left;
  padding-left: 10px;
}

.drier_innerinfor p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
}

.table_heading h3 {
  font-size: 17px;
  font-weight: bold;
  color: #000;
  line-height: normal;
}

.table_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table_heading_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table_box h3 {
  color: #404040;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}
.driver_customer_details {
  .tab_innrdv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 100%;
  }
}

.tabbing_top_heading {
  padding: 5px 15px;
}

.inner_drivercustomer_details .upper_details.lower_details {
  padding: 2px 2px 0px 2px;
}

.table_id {
  padding: 10px;
}

.upper_details.lower_details {
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.table_inner {
  margin: 0px 8px;
  padding: 0;
}

.table_outer_main {
  background: #fff;
  padding: 0 0px;
  margin-bottom: 20px;
  min-width: 2060px;
  border-radius: 15px;
}

.order_allright a {
  color: #000;
}

.table_right h5 {
  font-size: 13px;
  margin-top: 9px;
  font-weight: bold;
  line-height: normal;
}

.table_heading {
  border-bottom: solid 1px #eaeaea;
}

.table_id {
  width: 50px;
}

.card-head {
  border-bottom: unset;
  line-height: normal;
  min-height: auto;
}

.table_inner.width_increase {
  width: 280px;
  text-align: left;
}

.table_inner {
  width: 200px;
}

.order_dtails ul {
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order_inner_detail h4 {
  font-size: 14px;
  line-height: normal;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.order_inner_detail h5 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  line-height: normal;
}

.table_inner.table_order_dtail {
  width: 300px;
}

.order_dtails ul li.blue_block {
  border: solid 1px #c7e9ff;
}

.order_dtails ul li {
  width: 32%;
  padding: 18px 10px;
  border-radius: 10px;
}

li.blue_cancle {
  border: solid 1px #ffd3d3;
}

li.blue_complete {
  border: solid 1px #caffb2;
}

li.blue_complete .order_inner_detail h4 {
  color: #309b00;
}

li.blue_cancle .order_inner_detail h4 {
  color: #ff4d4d;
}

li.blue_block .order_inner_detail h4 {
  color: #0171bd;
}

.order_dtails ul li.all_block {
  width: 100%;
}

li.all_block {
  border: solid 1px #000000;
  margin-bottom: 10px;
}

li.all_block .order_inner_detail h4 {
  color: #000000;
}

li.all_block .order_inner_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.table_right {
  border: solid 1px #ddd;
  border-radius: 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-height: 180px;
  text-align: center;
}

.order_inner_detail {
  text-align: center;
}

.table_right figure img {
  height: 50px;
  margin-bottom: 10px;
}

.table_right h4 {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  color: #000;
}

.payment_box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.cash_payment h2 {
  color: #404040;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.cash_payment h4 {
  margin-top: 5px;
}

.cash_payment {
  border-bottom: solid 1px #ddd;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 7px;
  margin-bottom: 5px;
  flex-direction: column;
}

.cash_payment:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.block_button button {
  color: #000;
  padding: 12px 20px;
  font-size: 15px;
  letter-spacing: 0;
}

.block_button {
  margin-top: 15px;
}

.table_box .drier_innerinfor h3 {
  font-size: 22px;
}

.order_alllft {
  text-align: left;
}

.table th,
.card .table th,
.card .dataTable th {
  padding: 10px 6px;
  vertical-align: top;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  text-shadow: unset;
  color: #000;
}
.card .table td,
.table td,
.card .dataTable td {
  padding: 10px 8px;
  vertical-align: middle;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  text-shadow: unset;
  line-height: normal;
  color: #000000cf;
}

.rider_button_div button {
  margin-right: 20px;
}

.rider_button_div button:last-child {
  margin-right: 0px;
}

.view_summary_modainner h4 {
  color: #000;
  line-height: normal;
  font-weight: bold;
  font-size: 20px;
}

.view_summaryinner {
  margin-top: 25px;
}

.view_summaryinner h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.cash_payment span.label_span {
  color: #717171;
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
}

.cash_payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cash_payment.bannk_payment.total_payment {
  border-top: solid 1px #d2d2d2;
  margin: 10px 0;
  padding-top: 10px;
}

.cash_payment {
  padding: 4px 0;
}

span.currency {
  color: #000;
}

span.collection_amount {
  font-size: 16px;
  font-weight: bold;
}

#my_orders {
  padding: 30px;
  box-shadow: 0px 4px 16px #0000000f;
}

.common_main_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0;
}

.vendor_my_orders .table_outer_outer {
  height: auto;
  margin: 0;
  min-height: 800px;
}

.order_image figure {
  width: 50px;
  height: 50px;
}

.order_image figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.tab-content {
  height: auto;
  overflow-y: unset;
  padding: 0px 0;
}

.container {
  padding: 0;
}

.loader2_main {
  margin: auto;
  width: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.loader2_top {
  position: relative;
  height: 45%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.waves {
  position: relative;
  background: #3db4e4;
  width: 100px;
  height: 100px;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
}

.waves:before {
  position: absolute;
  background: #3db4e4;
  margin-left: -46px;
  margin-top: -46px;
  width: 200px;
  height: 200px;
  content: "";
  display: block;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -webkit-animation: 3s ease-out infinite wave-animate;
  animation: 3s ease-out infinite wave-animate;
}

.waves:after {
  position: absolute;
  background: #3db4e4;
  margin-left: -46px;
  margin-top: -46px;
  width: 200px;
  height: 200px;
  content: "";
  display: block;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  -webkit-animation: 3s ease-out 1.5s infinite wave-animate;
  animation: 3s ease-out 1.5s infinite wave-animate;
}

.loader_inner {
  background: #05a0e0;
  display: flex;
  width: 290px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -100px;
  margin: auto;
  padding: 10px 0;
}

.loader_inner figure {
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 8px 0 20px !important;
}

.loader_inner h4 {
  font-size: 13px;
  width: 80%;
  color: #fff;
  line-height: normal;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 511px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 0 28px 10px;
  background: #fff;
}

.card_Product.crd_padd {
  background: #fff;
  height: auto;
  padding: 20px;
}

/*Left*/

.modal.left.fade .modal-dialog {
  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/

.modal.right.fade .modal-dialog {
  right: 0px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */

.modal-content {
  border-radius: 0;
  border: none;
}

button.close {
  padding: 0;
  opacity: 1;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: 12px;
  background: transparent;
  width: 75px;
  height: 75px;
  border-radius: 100px 0px 0 100px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
  top: -43px;
}

.modal-header .close {
  margin-top: 20px;
}

.modal-header span {
  color: #fff;
  padding: 3px 9px;
  position: relative;
  font-size: 20px;
  z-index: 9;
}
.modal-header span::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  z-index: -1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.request_ride_inner .title_bar {
  width: 100%;
  text-align: left;
}

.modal-body .request_ride_inner .title_bar span {
  font-size: 18px;
  display: block;
  margin: 18px 0 13px;
  font-weight: 700;
}

.request_ride_inner .title_bar p {
  font-size: 16px;
  line-height: 23px;
  margin: 9px 0 17px;
}

.request_ride_inner form {
  width: 100%;
  border-bottom: solid 1px #ddd;
  padding-bottom: 20px;
}

.main_admin_details h2.main_heading {
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;
  font-weight: normal;
}

.cancel {
  color: red;
}

.admin_table tr {
  cursor: pointer;
}

.mzodal-body {
  overflow: scroll;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
}

.do_main_admin_details {
  display: flex;
  color: #000;
  flex-direction: column;
  padding: 0px 0 0 80px;
}

.main_admin_status {
  display: block;
}

.modal-body form p {
  color: #767676;
  font-size: 14px;
  margin-bottom: 6px;
}

.form-group.border_remove {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  padding: 0;
  margin: 0;
}

.request_ride_inner .form-group {
  display: flex;
  align-items: center;
}

.modal-body .blue_circle {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 100px;
  margin-right: 20px;
}

.modal-body .black_circle {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  background: #000;
  border-radius: 100px;
  margin-right: 20px;
}

.user_detail_fair {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: solid 1px #ddd;
  padding-bottom: 30px;

  .image_name {
    width: 45px;
    height: 45px;
    overflow: hidden;
    img {
      border-radius: 100px;
      width: 100%;
      height: 100%;
    }
  }
}

.description_name {
  width: 68%;
  margin-left: 12px;
}

.description_name .cashh {
  font-size: 16px;
  border-radius: 100px;
  max-width: 71px;
  text-align: center;
  padding: 3px;
  margin-top: 5px;
}

.km_hour h3 {
  font-size: 18px;
}
.review-rating {
  display: flex;
}

.km_hour h4 {
  font-size: 16px;
  color: #aaa;
}

.title_bar span {
  color: #d00 !important;
}

.trip_fare ul {
  padding: 0;
}

button.btn.btn-danger.mr-1.for_cancel {
  height: 39px;
  width: 69px;
  border-radius: 9px;
  margin: 0px 2px;
}

.total_bill {
  width: 100%;
  float: left;
  text-align: center;
}

.total_bill h4 {
  font-size: 20px;
  color: #000000c7;
  margin: 6px 0 20px;
  font-weight: 500;
}

.do_main_admin_details {
  padding: 20px 0 0 20px;
}

.total_bill h5 {
  color: #20a53e;
  font-size: 20px;
}

.information_title h2 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  border-bottom: 1px dotted;
}

.delivery_notes h3 {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

h3.headin_inner {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

.ordered_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px #ddd;
  padding: 7px 0;
}

span.quat_head {
  font-size: 13px;
  color: #999;
  margin-right: 6px;
}

.ordered_items span.amount_paid {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  width: 20%;
  text-align: right;
}

.delivery_notes {
  border-bottom: solid 1px #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/* Order Page UI */

#my_orders {
  padding: 0;
  box-shadow: unset;
  border-radius: 10px;
}

.driver_customer_details ul.nav.nav-tabs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  margin-bottom: 30px;
  justify-content: flex-start;
}

.card-bodyChangePassword.card_card.usr_profile_conts {
  flex-direction: column;
}

.usr_profile_conts h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.usr_profile_ins .form-group {
  width: 50%;
  padding: 5px 15px 0;
}

.card-bodyChangePassword {
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  width: 35%;
  margin: auto;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
  .cng-pswrd {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
.driver_customer_details ul.nav.nav-tabs {
  border: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-content {
  height: auto;
  overflow-y: unset;
}

.tab-content {
  border: none;
}

.upper_details_inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.upper_details_inner figure img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

a:not([href]):not([tabindex]) {
  cursor: pointer;
}

.user_details a.call_nbumer {
  color: #30373e;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0;
}

.user_details a.call_nbumer figure {
  width: 25px;
  margin-right: 10px;
}

.ratings i {
  font-size: 22px !important;
  margin: 0 7px 0 0;
}

.user_details h5 {
  color: #948f8f;
  line-height: normal;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}

.amount {
  font-size: 15px;
  font-weight: 400;
  color: #30373f;
  display: flex;
  align-items: baseline;
}

span.currency {
  color: #000;
}

.setel {
  display: flex;
  justify-content: space-between;
}

.day_time .items {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

.day_time .items ul {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.day_time .items ul li {
  width: 14%;
  margin: 0px 20px 20px 0px;
}
.day_time .items ul li input {
  width: 100%;
}

.day_time .items label {
  font-size: 18px;
  font-weight: 500;
  width: 12%;
  color: #000;
}

.amount .currency {
  display: inline-block;
  margin-right: 4px;
  font-weight: 500;
  color: #008000fa;
}

.driver_tracing_deails figure {
  width: 35px;
}

.driver_tracing_deails img {
  max-width: 100%;
  height: 100%;
}

ul.tracking_listing li::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 19px;
  height: 17px;
  width: 11px;
}

.inner_content_tabbing.accept_decline .upper_details .driver_details {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.inner_content_tabbing.accept_decline
  .upper_details.lower_details
  .driver_details {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.driver_customer_details .nav-tabs .nav-link {
  border: unset;
  border-radius: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0;
  padding: 10px 6px;
  font-weight: 400 !important;
}

#myModal2 .modal-header {
  position: absolute;
  background: #fff;
  left: -70px;
  height: 60px;
  width: 70px;
  border-radius: 50px 0 0 50px;
  top: 15px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}

/*Left*/

.modal.left.fade .modal-dialog {
  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/

.modal.right.fade .modal-dialog {
  right: 0px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.request_ride_inner h3 {
  margin: 10px 0;
}

.delivery_track_add,
.forgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.request_ride_inner .title_bar {
  width: 100%;
  text-align: left;
}

.modal-body .request_ride_inner .title_bar span {
  font-size: 18px;
  display: block;
  margin: 18px 0 13px;
  font-weight: 700;
}

.request_ride_inner .title_bar p {
  font-size: 16px;
  line-height: 23px;
  margin: 6px 0 15px;
  color: #000;
}

.description_name p {
  color: #91979c;
  font-size: 14px;
  font-weight: 500;
}
.trip_fare ul li {
  padding: 5px 0px;
}

.cancel {
  color: red;
}

.admin_table tr {
  cursor: pointer;
}

.modal-body form p {
  color: #767676;
  font-size: 14px;
  margin-bottom: 6px;
}

.form-group.border_remove {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  padding: 0;
  margin: 0;
}

.request_ride_inner .form-group {
  display: flex;
  align-items: center;
}

.modal-body .blue_circle {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 100px;
  margin-right: 20px;
}

.modal-body .black_circle {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  background: #000;
  border-radius: 100px;
  margin-right: 20px;
}

.request_ride_inner .driver_tracing_deails {
  padding: 0;
  margin: 0;
}

.request_ride_inner li.source {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: auto;
  width: 100%;
  margin: 10px 0;
}

.login-outer .right_login figure.login_img {
  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.request_ride_inner li.destination {
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  margin: 10px 0;
}
.request_ride_inner li.source span,
.request_ride_inner li.destination span {
  font-weight: bold;
}

.request_ride_inner h3 {
  font-size: 15px;
  color: #000;
  margin: 15px 0;
  font-weight: bold;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ed1c24 !important;
}

.request_ride_inner .itempic {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 5px;
}

.request_ride_inner .itempic img {
  width: 100%;
  height: 100%;
}

.km_hour h3 {
  font-size: 18px;
}

.km_hour h4 {
  font-size: 16px;
  color: #aaa;
}

.title_bar span {
  color: #d00 !important;
}

.trip_fare ul {
  padding: 0;
}

.trip_fare ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cashh {
  color: #000;
}

.trip_fare {
  color: #000;
  margin: 20px 0;
}

.total_bill h5 {
  color: #20a53e;
  font-size: 21px;
}

.delivery_notes h3 {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

span.usstl i {
  margin-right: 10px;
}

.ordered_tiems {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 60%;
  color: #000;
}

.ordered_tiems:last-child {
  border-bottom: unset;
}

span.quat_head {
  font-size: 13px;
  color: #999;
  margin-right: 6px;
}

.main_admin_status.main_admin_statusbutton button:last-child {
  margin-right: 0;
}

#menu5 .driver_details {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}
.main_admin_status {
  flex-direction: row;
  display: flex;
  gap: 14px;
}

#menu5 .upper_details.lower_details .driver_details {
  -webkit-filter: unset;
  filter: unset;
}

.driver_details {
  display: flex;
  width: 49%;
}
.main_admin_status h3 {
  font-size: 14px;
  color: #008000fa;
  font-weight: 500;
  line-height: normal;
}

.main_admincusdrdetail {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.driver_innerdetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-right: 12px;
  color: #000;
  border-radius: 5px;
  height: 40px;
  background: #fff;
}

.mat-form-field-label-wrapper {
  top: -18px;
  overflow: visible !important;
}

.mat-datepicker-toggle.mat-datepicker-toggle {
  color: #000;
  background: #dedede;
  height: 40px;
  width: 40px;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0px;
  right: 0;
}

.orders_dd .user_add_button,
.logut {
  display: none;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  position: inherit;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
  margin: 0 !important;
}

.mat-form-field input {
  padding: 0 10px;
  margin: 0;
}

textarea#body {
  min-height: 35px;
}

li.nav-item a:before {
  background: transparent;
}

.na_ratings {
  color: #4e4646;
}

.user_detail_fair.user_detail_fair_driver.blurDriver .image_name {
  transform: translateY(0px);
}

h3.cancel {
  color: red !important;
}

.time_class {
  font-weight: 200;
  font-size: 10px;
}

h2.main_heading {
  font-size: 15px;
  color: #000;
  margin-bottom: 5px;
  font-weight: bold;
}

.prouct_name {
  font-size: 10px;
}

.var_name {
  font-size: 10px;
}

.user_details h3 {
  font-size: 14px;
}

.user_details a.call_nbumer figure {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 10px 20px !important;
  background-color: #fff !important;
}

.date-filter-wrap {
  display: flex;
}
.rows .form-control input {
  width: 100%;
  height: auto;
  border-radius: 3px;
  padding: 3px 0px;
}

.search-filter-btn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lft {
  margin-right: 10px;
  width: 26% !important;
}

.up_file.up_single.bg_cvr input {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  padding: 0 !important;
  opacity: 0;
  border: unset;
  cursor: pointer;
}

.up_file.up_single.bg_cvr {
  min-width: 90px;
  padding: 10px;
  width: 90px;
  overflow: hidden;
}

.user_img.bg_proflcvr img,
.user_img.bg_proflcvr figure {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user_img.bg_proflcvr figure {
  border-radius: 50%;
  overflow: hidden;
}

.user_img.bg_proflcvr {
  overflow: unset;
}

.user_img.bg_proflcvr .up_file.up_single.bg_cvr {
  position: absolute;
  right: 0;
  bottom: -15px;
  height: 35px;
  width: 35px;
  background-color: #8c8c8c;
  border-radius: 50%;
  overflow: hidden;
  min-width: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  box-shadow: 0 3px 6px #00000026;
}

.user_img.bg_proflcvr .up_file.up_single.bg_cvr i {
  font-size: 14px;
}

.cdk-overlay-pane {
  background-color: #fff;
  border-radius: 5px;
}

.mod-btn {
  margin-top: 9px;
  margin-bottom: 15px;
}

.productser_img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #efefef;
  margin-right: 10px;
}

.productser_img i {
  position: absolute;
  left: 30%;
  top: 30%;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #2e2f37 !important;
}

.productser_img i:before {
  position: absolute;
  margin-left: 0px;
}

.botton_right_buttons {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.no_data_found h4 {
  font-size: 16px;
}

/**sidebar css end**/

/**dashboard css**/

.card {
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 10px;
  box-shadow: 0 4px 16px #00000008;
}

.card-header {
  margin: 10px 0 !important;
  background-color: unset;
  border-bottom: unset;
  padding: 0 !important;
}

.card-header h6 {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  margin-left: 12px;
  color: #000000;
}

.col-card {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-card i {
  color: #ffffffc7;
  font-size: 28px;
}

.card .h5 {
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
  color: #000;
  font-size: 22px;
}
.no-gutters .h5 {
  color: #fff;
}
.card .h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #fff;
  text-transform: capitalize;
}

.card_flex .card .align-items-center {
  height: 100%;
}

/**dashboard css end**/

/**navabr changes**/

a#alertsDropdown {
  background: #ffeae6;
  color: #ff6d4d;
  height: 50px;
  border-radius: 3px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
}

a#messagesDropdown {
  background: #f4f6fd;
  color: #2f4cdd;
  height: 50px;
  border-radius: 3px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
}

.navbar-expand .navbar-nav {
  align-items: center;
}

.pulse-css {
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 0.4rem;
  position: absolute;
  background: #2f4cdd;
  right: 5px;
  top: 0.6rem;
  width: 0.4rem;
}

.pulse-css:after,
.pulse-css:before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -0.2rem;
  background-color: #2f4cdd;
  margin: auto;
  transform: scale(0.3);
  transform-origin: center center;
  -webkit-animation: pulse-me 2s linear infinite;
  animation: pulse-me 2s linear infinite;
}

@-webkit-keyframes pulse-me {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

/**navabr changes**/

/**table layout css**/

.field-single {
  width: 47%;
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
}

.field-single input,
.form-control input {
  width: 100%;
  height: 20px;
  border: unset;
  border-radius: 3px;
  padding-left: 10px;
}

.field-single label,
.form-control label,
.form-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px !important;
}

.table-body {
  padding: 0 0.25rem;
}

table tr {
  border-bottom: solid 1px #e2e2e2;
}

/**table layout css**/

.badge {
  padding: 0.5em 0.4em !important;
}

.bg-gradient-primary {
  height: 100% !important;
}

.switch-medium.switch-labeled {
  width: 71px !important;
}

.switch-medium.switch-labeled.checked small {
  left: 40px !important;
}

.card_flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.card_flex .hvrcursor {
  width: 24%;
  box-shadow: 0px 0px 3px 2px #00000008;
  border-radius: 8px;
}

.user_title {
  margin-bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.outlettable-borderd {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
}

.outlet_outer_lis {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 180px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 4px 16px #0000001a;
  width: 49%;
}

.snu {
  position: absolute;
  background-color: var(--primary-color) !important;
  left: 0;
  top: 0;
  width: 35px;
  height: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px 0 0 0;
  color: #fff;
}
.otr-fx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.show_address {
  padding: 5px 0;
  font-size: 14px;
  height: 112px;
  overflow: hidden;
  color: #000000;
  line-height: 22px;
  width: 72%;
}

.show_address h4 {
  padding: 8px 0 0 0;
  font-weight: 500;
  font-size: 20px;
  color: #202020;
}

.outlet_outer_lis:nth-child(4),
.outlet_outer_lis:nth-child(8),
.outlet_outer_lis:nth-child(12) {
  margin-right: 0;
}

.hvrcursor {
  cursor: pointer;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #2b52c5;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

span.red-str {
  color: #ff0000ad;
  margin: 0px 0px 0px 4px;
}

.loader {
  margin: 1rem auto 2rem;
  width: 55px;
  height: 55px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #2b52c5;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* end Safari */

li.nav-item a img {
  margin-right: 5px;
  border: 1px solid #a0a0a0;
  padding: 2px;
}

.ml-auto span {
  display: none;
}

.stars form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.clendr .mat-form-field {
  background: #ffffff;
  border: 1px solid #d1d3e2;
}

.cont .stars label.star {
  padding: 3px;
  font-size: 22px;
}

.cont .stars input.star-5:checked ~ label.star:before {
  text-shadow: 0 0 2px #000000db !important;
}

.pagination_ui {
  padding: 30px 0px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination_ui .ngx-pagination .current {
  background: $primary_color;
}

.table-bordered td,
.table-bordered th {
  vertical-align: middle !important;
  text-align: left;
}

td img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 40px;
  width: 40px;
}

.text-right.geofence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
}

.geofence_btn {
  width: 50%;
  text-align: right;
}

.row.top_title_geo {
  margin-left: 0;
}

.col-md-6.user_serch_btn {
  display: flex;
  align-items: flex-end;
}

.user_select_bx label {
  margin-bottom: 0px;
}

/**form css**/

.btn-margin-e {
  margin-top: -32px;
}

.pd-de {
  padding-top: 16px;
  padding-left: 11px;
}

.pd-de input {
  padding: 10px;
}

input.checkbox {
  display: inline-block;
}

.table-responsive input {
  -webkit-appearance: checkbox !important;
}

.for-search {
  position: relative;
}

.card_inner {
  margin: 10px;
}

.blccard-img {
  height: 140px;
  overflow: hidden;
  position: relative;
  align-items: center;
  background: #00000005;
  width: 100%;
  justify-content: center;
  display: flex;
}

.b-name {
  position: absolute;
  bottom: 0px;
  left: 0;
  background: #39801d;
  color: #fff;
  padding: 3px 9px;
  border-top-right-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

.mat-select-arrow-wrapper {
  position: relative;
  top: 1px;
}

.field-single label {
  margin-bottom: 0;
}

.inp-field {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0;
}

.card-sample .single-cards:nth-child(4n + 4) {
  margin-right: 0px;
}

.pd-de .row {
  justify-content: flex-end;
}

.ad-btnn {
  width: 100%;
  text-align: right;
}

.pd-de {
  width: 100%;
  text-align: right;
}

.new-chc input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.new-chc .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.new-chc .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.new-chc input:checked ~ .checkmark:after {
  display: block !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

// .dropdown-item,
// ul.ml-autosss {
//   display: none;
// }

.clendr .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
}

.fd_th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .ad_slots {
    width: 46%;
    margin-bottom: 15px;
  }
}

.pt-3.pb-2 {
  width: 100%;
}

.fd_th {
  .slt_time_outer {
    width: 100%;
    .slt_time_inr {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .ui_input {
    position: relative;
    i.fas.fa-clock.slot_time {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}

.filter-drpdwn {
  width: 100% !important;
}

.field-single.fr-full.size-full {
  width: 100%;
}

.user_filter {
  width: 350px;
  position: relative;
  box-shadow: 0 4px 16px #0000001a;
  display: flex;
  border-radius: 10px;
}

.rwlsis p {
  font-size: 14px;
  color: var(--primary-color);
}

.user_filter .form-control {
  border-radius: 5px;
  height: 38px;
  border: 1px solid #f1f1f1;
  padding: 10px 37px 10px 35px;
}

.rest {
  margin: 0 10px;
}

.empty_quantity {
  background-color: grey;
  color: whitesmoke;
}

.three_formgroup .form-group {
  width: 33%;
}

.three_formgroup {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.user_filter.user_selectfilter select {
  background: url(assets/icon/slect_arrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding-right: 30px;
}

.content_based {
  min-height: 600px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $primary_color !important;
  border-color: $primary_color !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $text_white_color !important;
  background-color: $primary_color;
}

.ratings i.rated,
.modal-body .request_ride_inner .title_bar span {
  color: $primary_color;
}

.inner_drivercustomer_details select,
.modal-body .blue_circle,
.description_name .cashh {
  background: $primary_color;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background: transparent;
}

span.import_icon i:before {
  font-size: 12px;
  color: #fff;
}

.drpdwn_action {
  background: #fff;
  box-shadow: 0 4px 16px #00000008;
  width: 190px;
  padding: 10px 0;
  border-radius: 3px;
  border: 1px solid #e2e2e2;
  opacity: 0;
  z-index: -99;
  transition: 0.2s ease-in;
  position: absolute;
  top: 40px;
  left: -60px;
}

.drpdwn_action li {
  line-height: 34px;
  padding: 0px 10px;
  border-left: 3px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
}

.drpdwn_action li a {
  color: #282828 !important;
  font-size: 15px;
  display: block;
}

.imex_ddwn:hover .drpdwn_action {
  opacity: 1;
  z-index: 9999;
  width: 190px;
}

.combo_action span {
  position: relative;
  cursor: pointer;
}

.combo_action i {
  color: #777070;
}

.combo_action i:before {
  margin-left: 0;
  font-size: 14px;
  position: relative;
  left: 11px;
}

.combo_action span:hover .drpdwn_action {
  opacity: 1;
  z-index: 99;
}

.cate_data .drpdwn_action {
  width: 170px;
  top: 25px;
  left: inherit;
  right: -2px;
}

.allclc-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  background: #eaeaea;
  z-index: 999;
  padding: 18px;
  text-align: right;
}

.p-search_btn i:before {
  font-size: 13px;
  color: var(--primary-color);
}

.p-search_btn input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  color: #000;
  padding: 6px;
  position: relative;
  padding-left: 13px;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.btn.btn-primary.common-btn.btn_ellipse:hover .drpdwn_action {
  opacity: 1;
  z-index: 999;
  width: 162px;
}

.btn.btn-primary.common-btn.btn_ellipse {
  position: relative;
  cursor: pointer;
}

.container,
.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-link {
  color: $primary_color !important;
}

.form-group.form-actions.add_edit.mb-3.pl-4 {
  padding-bottom: 29px;
  padding-left: 40px !important;
  margin-top: -19px;
}

.swal2-actions button {
  min-width: 131px !important;
}

.filter-prd h3 {
  font-size: 18px;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}

.filter-prd svg path {
  fill: #fff;
}

.filter-prd {
  background: #f1f1f1;
  border-radius: 10px;
  text-align: right;
  margin: 10px 0;
}

.filter-prd img {
  width: 29px;
  position: relative;
  top: -1px;
}

.filter-drpdwn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter-main.full-filter {
  width: 100%;
}

.filter-main {
  text-align: left;
  margin: 19px 0;
  width: 32%;
}

.filter-prd .mat-expansion-panel {
  background: transparent;
  box-shadow: none;
}

.select-category select {
  width: 100%;
  height: 44px;
  padding-left: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
  color: #000 !important;
}
.Header_fnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header_fnt h3 {
  padding: 20px 0px;
  color: black;
  font-weight: 500;
}

.hvrcursor {
  cursor: pointer;
}
.varient_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .fa-times:before {
    color: #f91e1e !important;
  }
}

.logo_login {
  padding: 0px 0px 30px 0px;
}
.logo_login img {
  height: 115px;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-infix {
  line-height: 33px;
  font-size: 14px;
}

.mat-form-field-label span {
  padding-left: 12px;
  position: relative;
  top: 1px;
  color: #949494;
  font-weight: 400;
}

.mat-form-field-infix {
  border: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}

p.b-name,
.new-chc input:checked ~ .checkmark {
  background: $primary-color;
}

h3.cancel {
  color: #ff0000 !important;
  font-size: 14px;
  font-weight: 600;
}

.user_filter_right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.filter-prd h3:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -30px;
  width: 24px;
  height: 25px;
  background: url(assets/icon/filter.png);
  background-size: 24px;
}

.driver_customer_details .tab-pane {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user_title .user_title_inner h6 {
  font-size: 28px;
  font-weight: 500;
  padding: 0px 0px 10px 0px;
  color: #000000;
}

.inner_content_tabbing.ng-star-inserted {
  width: 49%;
}

.product_action i.fas.fa-edit,
i.fa.fa-trash {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_type {
  font-size: 12px;
  color: #000000c7;
  background: #e5e8f7e8;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px;
  padding: 8px 12px;
  font-weight: 500;
  box-shadow: 0px 0px 2px #0000002e;
}

.driver_tracing_deails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tracking_right_detils ul {
  padding-left: 0;
}

.user_img img {
  width: 100%;
}

li.source {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
}

.user_imgcover {
  width: 100%;
  position: relative;
  min-height: 360px;
  margin-bottom: 40px;
  background: #fff;
}

.user_imgcover figure {
  width: 100%;
}

.upper_details_inner .duser_img,
.driver_details .duser_img {
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.upper_details_inner .duser_img img,
.driver_details .duser_img img {
  height: 100%;
  width: 100%;
}

.upper_details_inner .user_img,
.driver_details .user_img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  position: inherit;
}

.onpfline {
  margin-right: 20px;
  display: flex;
}
select.form-control.ng-touched.ng-dirty.ng-valid {
  padding: 20px !important;
}

.list_booking_details h2 {
  p {
    padding: 3px 0px 0px 0px;
    font-weight: 500;
  }
}

.tabbing_top_head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
}

.tabbing_top_innerleft,
.tabbing_top_innerright {
  width: 100%;
}

.user_details h3 {
  font-size: 13px;
  font-weight: 500;
  color: #060606;
  line-height: normal;
  margin-bottom: 5px;
}

.filter-drpdwnoi {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.drpdwnoi {
  width: 30%;
  margin-right: 10px;
}

.inner_drivercustomer_details {
  border-radius: 10px;
  transition: ease-in-out 0.5s;
  background: #fff;
  border: 1px solid #dedede;
  flex-direction: column;
  padding: 10px;
  height: 210px;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
}

.text-center.add-on h4 {
  padding: 20px 0;
}

.no_data_found {
  width: 100%;
  background: #fff;
  text-align: center;
  box-shadow: 0 4px 16px #00000008;
  padding: 20px 0;
  border-radius: 10px;
  color: #000;
}

.form-control.mat-select {
  width: 100px !important;
  height: 35px;
  border: 1px solid #e9dfdf;
  padding: 4px 4px;
}

.srno-dr {
  position: absolute;
  background-color: var(--primary-color);
  color: #fff;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  border-radius: 10px 0 0 0;
}

.product_image {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #f5f5f5;
  justify-content: center;
  margin-right: 20px;
  position: relative;
}

.pro_list_main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: stretch;
  flex-direction: row;
  gap: 25px;
}

.pro_list {
  display: flex;
  width: 49%;
  position: relative;
  background: #fff;
  box-shadow: 0 4px 16px #0000001a;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}

.pro_list:nth-child(3) {
  margin-right: 0px;
}

.pr_info p {
  font-size: 12px;
  word-break: break-word;
}

.pr_info h2 {
  font-size: 20px;
  color: #000;
}

.b_cate {
  display: flex;
  justify-content: space-between;
}

.b_cates h4 {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.b_cate {
  display: flex;
  justify-content: space-between;
}

.variantsin,
.date_Ups {
  font-size: 14px;
}

.acticn {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.date_downs {
  margin-left: 58px;
  img {
    height: 100px;
  }
}

a.View.btn.btn-primary.common-btn,
a.Edit.btn.common-btn,
a.Delete.btn.common-btn {
  color: #fff !important;
}

.Edit.btn.common-btn {
  padding: 7px 7px;
  width: 35px;
  background: #32d838;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_admin_status .btn {
  line-height: 15px !important;
}

.Delete.btn.common-btn {
  padding: 7px 7px;
  width: 35px;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.b_cates {
  display: flex;
}

.dle .mat-checkbox-inner-container {
  display: inline-block;
  height: 40px;
  line-height: 0;
  margin-right: 0;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 40px;
  flex-shrink: 0;
}

.dle {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}

.sub_main_heading h2 {
  font-size: 28px;
}

.dle label.mat-checkbox-layout,
.amount h3 {
  margin: 0;
}

.dropdown .dropdown-menu a {
  color: #000 !important;
  font-size: 16px;
}

.blc-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0;
}

.charts_dashcardcard-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.hvrcursor .card-body {
  flex: 1 1 auto;
  padding: 10px;
  height: 75px;
  border-radius: 6px;
}

.charts_dash .card_card {
  background: #fff;
}

.mat-dialog-actions {
  flex-wrap: nowrap !important;
}
.mat-dialog-content {
  overflow: hidden !important;
}
.mat-dialog-container {
  width: 500px !important;
}
.text-left .signup {
  margin-top: 10px;
}
.responsive_nav {
  display: none;
}

.iti.iti--allow-dropdown {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  width: 13% !important;
}

.upper_details.lower_details
  .driver_details
  .driver_tracing_deails
  .tracking_listing {
  width: 100%;
}

/*-----import-file css----*/
.dftlp {
  position: absolute;
  align-items: center;
  flex-direction: column;
  display: flex;
  top: 100px;
  left: 150px;
}
.dftlp figure {
  width: 50px;
}
.outer_uploadsshdf.bigholderhor.default_viewer {
  position: relative;
  border: 1px solid #ededed !important;
  border-radius: 5px;
}

/************************************/
/*********************swati css start*************/

/******dashboard**********/

.user_title .user_title_inner h6 {
  font-size: 28px;
  color: #000000;
  font-weight: 500;
  padding: 0px 0px 10px 0px;
}
.my-dsbrd .hvrcursor .card-body {
  padding: 10px;
  height: 120px;
  border-radius: 6px;
}
.my-dsbrd .h5.mb-0.dshbrd_dta {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}

/***************order************/
.resp_sec {
  padding: 70px 0px;
}

.my-ordr li.nav-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.links-rd .navbar-nav li a.nav-link.active {
  border-bottom: 4px solid var(--primary-color) !important;
}

.my-ordr figure {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-ordr .driver_customer_details .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500 !important;
  color: #a5a5a5;
}
.my-ordr .driver_customer_details .nav-tabs a.nav-link.active {
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.activeClass {
  border-bottom: 4px solid var(--primary-color) !important;
  fill: var(--primary-color);
}

.my-ordr .main_admin_details p {
  font-size: 14px;
  width: 100%;
  color: #000000;
  font-weight: 600;
  margin-top: 0px;
  line-height: 22px;
}
.my-ordr .main_admin_details h2 span {
  font-size: 14px;
  font-weight: 700;
  color: #202020;
  margin-bottom: 0px;
  line-height: 22px;
}

.my-ordr .list_booking_details h2 {
  font-size: 14px;
  width: 100%;
  color: #000000;
  font-weight: 600;
  line-height: 22px;
}

.my-ordr .driver_customer_details ul.nav.nav-tabs {
  position: relative;
  border-bottom: 3px solid #cec7c7;
}

.my-ordr .tabbing_top_heading h3 {
  font-size: 14px;
  font-weight: 600;
  color: #060606;
  line-height: 22px;
  margin-bottom: 5px;
}
.my-ordr .main_admin_details {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 14px;
  border-bottom: solid 1px #eaeaea;
  flex-wrap: wrap;
}

.my-ordr .user_details h4 {
  color: #000000;
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.my-ordr figure svg {
  width: 20px;
  height: 20px;
  margin: 0px;
}
/*----------header------*/
.links-rd li.nav-item a {
  color: var(--primary-color) !important;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 20px !important;
  border-bottom: 3px solid transparent;
}
.navbar-nav > li {
  position: relative;
}
.navbar-nav {
  position: relative;
}

.onpfline .mat-slide-toggle-thumb {
  background: #ff4400;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(212 200 204 / 54%);
}

.onpfline .mat-slide-toggle-bar {
  background-color: rgb(225 214 214 / 38%);
}

/*------------My manegment----------*/
.txt-lt {
  width: 100%;
  text-align: right;
  color: var(--primary-color) !important;
}
.txt-lt a {
  font-size: 16px;
  font-weight: 500;
}

.Edit .btn .common-btn {
  line-height: normal;
  padding: 7px 10px;
  border-radius: 6px;
  border: unset;
  font-size: 16px;
  width: 35px;
  background: red;
  color: #fff;
}

span.fa.fa-search.form-control-feedback {
  border-radius: 5px;
  height: 42px;
  border: none;
  font-size: 16px;
  position: absolute;
  color: var(--primary-color);
  top: 12px;
  left: 10px;
}
.txt-lt a {
  position: relative;
}
.txt-lt a::after {
  content: "";
  width: 100%;
  margin: auto;
  height: 2px;
  position: absolute;
  bottom: 0px;
  background-repeat: no-repeat;
  background-color: var(--primary-color);
  right: 0;
  left: 0;
}

.wrapper_sec table {
  width: 100%;
  margin-top: 0px;
}

td,
th {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
td .nmbr {
  padding: 5px 13px;
  background-color: var(--primary-color);
  color: #fff;
  border: unset;
  font-size: 16px;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}
td .mat-select {
  text-align: center;
}
td .field-single {
  width: 60%;
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.wrapper_sec table figure {
  width: 50px;
  margin: 8px auto 8px;
  height: 50px;
}
.wrapper_sec table figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

table td .field-single input {
  width: 100% !important;
  height: 35px;
  border: 1px solid #ddd;
  padding: 5px 11px;
  border-radius: 5px;
}
tr {
  border: unset;
}

/*-------chang-password-----*/

.cng-pswrd figure {
  width: 80px;
  height: 90px;
  margin: auto;
}
.cng-pswrd .user_title_inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cng-pswrd .input-group {
  padding: 5px 0px;
}
.cng-pswrd .card-bodyChangePassword {
  padding: 10px;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.14rem;
  margin-left: 0.6rem;
  font-size: 95%;
  color: #f55353;
}

/*---------------rating review--------*/

.ussubs_rat h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}
.rating {
  padding: 20px;
}
.ussubs_rat h2 span {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.rating .user_title .user_title_inner h6 {
  font-size: 30px;
}
.usrwlis {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.usrevlist ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 20px 0px;
}
.usrevlist li {
  border-bottom: unset;
  width: 24%;
  box-shadow: 4px 0px 1px 2px #0000000a;
  border-radius: 10px;
  padding: 20px;
}
.rwlsis h2 {
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.rwlsis h3 {
  font-size: 16px;
  font-weight: 400px;
}
.ussubs_rat h4 {
  color: black;
}
.review .fa-star .fas {
  color: #a8a8a8;
}
.usrwlis figure {
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.review-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review-rating span {
  color: #ed1c24;
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}

/*-------outlet----*/
.product_action .btn.btn-success.common-btn {
  padding: 7px 7px;
  border-radius: 6px;
  width: 38px;
  background: #32d838;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.btn.btn-success.common-btn figure,
.btn.btn-danger.common-btn figure,
.Delete figure,
.Edit figure {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn-success.common-btn figure svg,
.btn.btn-danger.common-btn figure svg,
.Delete svg,
.Edit svg {
  max-width: 100%;
  height: 100%;
}
.product_action .btn.btn-danger.common-btn {
  padding: 7px 7px;
  border-radius: 6px;
  width: 38px;
  background: #d40328;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.btn.btn-danger.common-btn figure svg,
.btn.btn-danger.common-btn figure svg {
  max-width: 100%;
  height: 100%;
}
.owner_field {
  .input-group {
    position: relative;
    figure {
      position: absolute;
      right: 10px;
      top: 6px;
      z-index: 9;
    }
  }
}
.day_time select#inputGroupSelect02 {
  background: #f5f5f5;
  border: unset;
  border-radius: 10px;
}

.input-group figure {
  margin: 0;
  position: absolute;
  right: 10px;
  top: 5px;
}

.timepicker__header {
  padding: 15px 30px;
  background: var(--primary-color) !important;
}
.clock-face__number > span.active {
  color: #fff;
  background: var(--primary-color) !important;
}

span.clock-face__clock-hand {
  background: var(--primary-color) !important;
}
.timepicker-button {
  color: var(--primary-color) !important;
}
.timepicker-dial__container {
  justify-content: center !important;
}
.mat-date-range-input-start-wrapper {
  position: relative;
  overflow: hidden;
  width: 37% !important;
}
.login_formss .logo_login img {
  height: 55px;
}

.ct_dtail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 1px var(--primary-color);
  background: #fff;
  position: relative;
  width: 49%;
  padding: 20px;
  height: 200px;
  border-radius: 10px;
  border: 2px solid #00000024;
  ul {
    display: flex;
    gap: 10px;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    line-height: 30px;
    span {
      font-weight: 400;
      font-size: 15px;
    }
  }
}

.tbl_fdx {
  display: flex;
  gap: 10px;

  .btn-primary:hover {
    background: #008000f7 !important;
  }
  li {
    .acpt {
      background: #00800061;
      color: #008000d4;
      padding: 8px;
      border-radius: 0px 7px 0px 7px;
      width: 80px;
      text-align: center;
      font-weight: 600;
      border: unset;
    }
    .reject {
      background: #f00303b5;
      color: #fff;
      padding: 8px;
      border-radius: 0px 7px 0px 7px;
      width: 80px;
      text-align: center;
      font-weight: 400;
    }
  }
}

.fdx_intro {
  position: relative;
  .date_piker {
    width: 15%;
    position: absolute;
    top: 7px;
    right: 0;
    z-index: 9;
  }
}

.sales_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .new_select {
    display: flex;
    // width: 26%;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
}

.no_dta {
  text-align: center;
}

/*------------outlet------*/

/*------swati responsive css-start------*/

@media (max-width: 1800px) {
  .driver_customer_details .tab_innrdv {
    gap: 28px;
  }
  .tracking_right_detils ul li {
    font-size: 14px;
  }
  .amount h2 {
    font-size: 16px;
  }
  .usrevlist ul {
    gap: 18px;
  }

  .text-left .signup {
    margin-top: 20px;
  }
  .my-dsbrd .card_flex {
    gap: 18px;
  }
}
@media (max-width: 1600px) {
  .fd_th .ad_slots {
    width: 45%;
  }
  .my-ordr .main_admin_details {
    padding: 12px 14px 5px;
  }
  .driver_customer_details .tab_innrdv {
    gap: 25px;
  }
  .product_image {
    width: 60px;
    height: 60px;
  }
  .my-dsbrd .card_flex {
    gap: 16px;
  }
}

@media (max-width: 1400px) {
  .tym-slot-mn .slot-in {
    width: 11% !important;
  }
  .pro_list_main {
    gap: 20px;
  }
  .driver_customer_details .tab_innrdv {
    gap: 20px;
  }
  .rigefnevs {
    width: 80%;
  }
  .right_login {
    width: 60%;
  }
  .right_login figure {
    width: 600px !important;
    margin: auto;

    height: auto !important;
    .my-dsbrd .card_flex {
      gap: 15px;
    }
  }
}

.count-inner .rgt {
  display: flex;
  gap: 12px;
  align-items: center;
}

span.add_mrebx {
  box-shadow: 0px 0px 4px 2px #0000002b;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  i.fa-solid.fa-pencil {
    color: #008000f7;
    font-size: 18px;
    cursor: pointer;
  }
  i.fa.fa-trash {
    color: #ff0000c7;
    font-size: 18px;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .my-dsbrd .card_flex {
    gap: 12px;
  }
  .wrapper_sec th p {
    font-size: 14px;
    color: black;
  }
  td {
    color: #202020;
    font-size: 14px;
  }
  .notflistit span.spanned {
    width: 18%;
  }

  .notflistit h4 {
    width: 53%;
  }
  .driver_customer_details li.nav-item svg {
    display: none;
  }
}
@media (max-width: 1150px) {
  .links-rd li.nav-item a {
    font-size: 16px;
    padding: 0px 12px !important;
  }
  .driver_customer_details ul.nav.nav-tabs figure,
  .links-rd .nav-item:nth-child(1):before,
  .my-ordr .driver_customer_details ul.nav.nav-tabs:after,
  .my-ordr li.nav-item::before {
    display: none;
  }

  .my-ordr .driver_customer_details ul.nav.nav-tabs {
    border-bottom: unset;
  }
  .driver_customer_details .tab_innrdv {
    gap: 18px;
  }
  .my-ordr .main_admin_details p {
    font-size: 13px;
  }
  .rigefnevs {
    width: 83%;
  }
}

@media (max-width: 992px) {
  .topbar .nav-item .nav-link h2 {
    font-size: 12px;
  }
  .topbar.navbar-light .navbar-nav .nav-item .nav-link {
    white-space: nowrap;
  }
  .notsid i {
    font-size: 20px;
  }
  .my-ordr .main_admin_details {
    padding: 0;
  }
  .card_flex {
    gap: 12px;
  }
  .card_flex .hvrcursor {
    width: 32%;
  }
  .driver_customer_details .tab_innrdv {
    gap: 12px;
  }
  .my-ordr figure {
    width: 25px;
    height: 25px;
  }
  .tabbing_top_heading {
    padding: 5px 5px;
  }

  .rigefnevs {
    width: 82%;
  }
  .links-rd .nav-item:nth-child(1):before {
    width: 79%;
    bottom: 3px;
  }
  .links-rd li.nav-item a {
    padding: 0px 7px !important;
  }
  .links-rd li.nav-item a {
    font-size: 14px;
  }
  .onpfline {
    margin-right: 10px;
  }

  .main_admin_details p {
    font-size: 12px !important;
  }

  .order_type {
    font-size: 12px !important;
    height: 21px;
    padding: 10px 9px;
  }
  .main_admin_status h3 {
    font-size: 12px !important;
  }

  .my-ordr .user_details h4 {
    font-size: 12px !important;
    line-height: 18px;
  }
  .my-ordr .user_details a.call_nbumer {
    font-size: 13px;
  }
  .amount {
    font-size: 13px !important;
  }
  .my-ordr .tracking_right_detils ul li {
    font-size: 13px !important;
  }
  .main_admin_details h2 span,
  .list_booking_details h2 {
    font-size: 12px !important;
    line-height: 18px;
  }
  .my-ordr h2 {
    font-size: 12px !important;
    line-height: 18px;
  }

  .my-ordr .main_admin_details .gry {
    font-size: 14px;
  }
  .sub_rat h1 {
    font-size: 70px;
  }
  .ralstl i.fas {
    font-size: 16px;
  }
  .review {
    padding: 6px 0px;
  }
  .ratlis p {
    font-size: 18px;
  }
  .avrat li span {
    font-size: 14px;
    width: 20%;
  }
  .rwlis h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .ratlis {
    margin-left: 40px;
  }
  .user_filter {
    width: 269px;
  }
  .user_title .user_title_inner h6 {
    font-size: 20px;
  }
  .my-ordr li.nav-item::after {
    display: none;
  }
  .day_time .items ul li {
    width: 20%;
    margin: 0px 20px 20px 0px;
  }

  .col-card figure svg {
    width: 25px;
    height: 25px;
  }
  .my-dsbrd .h5.mb-0.dshbrd_dta {
    font-size: 16px;
  }

  .my-ordr .driver_customer_details .nav-tabs .nav-link {
    font-size: 14px;
  }
  .my-ordr .tabbing_top_heading h3 {
    font-size: 12px;
  }
  .my-ordr .main_admin_details h2 span {
    line-height: 14px;
  }
  .my-ordr .main_admin_details p {
    font-size: 12px;
    line-height: 18px;
  }
  td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
  }
  th {
    padding: 20px 5px;
  }
  .form-control.mat-select {
    width: 100% !important;
    height: 29px;
    border: 1px solid #ddd;
    padding: 4px 5px;
  }
  .form-control {
    font-size: 12px;
  }
  Table p {
    font-size: 12px;
  }
  td .field-single {
    width: 75%;
  }
  .wrapper_sec th p {
    font-size: 12px;
  }
  .wrapper_sec table figure {
    width: 40px;
    margin: 8px auto 8px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  .table th,
  .card .table th,
  .card .dataTable th,
  .card .table td,
  .table td,
  .card .dataTable td {
    font-size: 13px;
  }

  .my-ordr li.nav-item {
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .links-rd .nav-item:nth-child(1):before {
    display: none;
  }
  .card-header h6 {
    font-size: 20px;
    margin-right: 0px;
  }

  .sub_rat .ussubs_rat h2 {
    font-size: 80px;
  }

  .sub_rat {
    margin: 27px 0 0 0;
  }
  .usratlis .stl i {
    font-size: 14px;
  }
  .usorrtor p {
    margin: 5px 0;
  }
  .rwlsis h2 {
    font-size: 16px;
  }

  .usrevlist ul {
    width: 45%;
    margin: 0px 10px 10px 0px;
  }

  .ralstl {
    margin-top: 20px;
  }

  .my-ordr .tabbing_top_heading h3 {
    margin-bottom: 6px;
  }

  .pro_list {
    width: 100% !important;
  }
  .user_filter {
    width: 220px;
  }
  .notsid i {
    font-size: 20px;
  }
  .notsid i {
    font-size: 20px;
  }
  .col-card figure svg {
    width: 20px;
    height: 20px;
  }

  .user_title .user_title_inner h6 {
    font-size: 16px;
  }
  .my-dsbrd .h5.mb-0.dshbrd_dta {
    font-size: 14px;
  }
  .card .h4 {
    font-size: 14px;
  }
  .ml-auto {
    display: none;
  }
  .outlet_outer_lis {
    width: 100% !important;
  }
  .card-bodyChangePassword {
    padding: 10px;
  }
  .col-md-12,
  .col-md-4 {
    padding: 0px;
  }

  .day_time .items ul li {
    width: 25%;
    margin: 0px 20px 20px 0px;
  }
  .cardChangePassword {
    width: 100%;
  }
  .my-ordr .user_details a.call_nbumer {
    font-size: 10px;
  }
  .inner_drivercustomer_details {
    margin-bottom: 8px;
  }
  .my-ordr .tabbing_top_heading h3 {
    font-size: 14px;
  }
  .rigefnevs {
    width: 15%;
  }
  td .field-single {
    width: 40%;
  }
  .form-control.mat-select {
    width: 100% !important;
    height: 25px;
    border: 1px solid #ddd;
    padding: 4px 5px;
  }
  .col-md-6.high {
    height: 0px;
  }
}
@media (max-width: 600px) {
  .avrat li span {
    width: 26%;
  }
  .user_title .user_title_inner h6 {
    font-size: 20px;
  }

  .user_title .user_title_inner h6 {
    font-size: 20px;
    text-align: centerapp-header;
  }
  .outlet_outer_lis {
    width: 100% !important;
  }
  .col-card figure svg {
    width: 25px;
    height: 25px;
  }
  .card_flex .hvrcursor {
    width: 48%;
  }
  .my-ordr li.nav-item {
    width: 15%;
  }
  .my-ordr figure svg {
    width: 18px;
    height: 18px;
    margin: 0px;
  }
}
@media (max-width: 575px) {
  .onpfline .mat-slide-toggle-bar {
    background-color: #fff;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #fff;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: #ed1c24;
  }
  .wrapper_sec {
    padding: 60px 0px;
  }
  :root .wrapper_sec {
    padding: 70px 0px;
  }
  .sub_rat .ussubs_rat h2 {
    font-size: 50px;
  }
  .pr_info h2 {
    padding: 5px 0px;
    font-size: 16px;
  }
  .avrat li span {
    width: 32%;
  }
  .ralstl i.fas {
    font-size: 14px;
  }
  .ralstl i.fas {
    font-size: 13px;
  }
  .ratlis p {
    font-size: 16px;
  }
  .catbtn_ui {
    width: 100%;
    justify-content: flex-start;
  }
  .btn-primary {
    font-size: 12px;
  }
  .btn-danger {
    font-size: 12px;
  }
  .my-dsbrd .h5.mb-0.dshbrd_dta {
    font-size: 18px;
  }
  .upper_details.lower_details {
    flex-wrap: wrap;
  }
  .upper_details_inner .user_img {
    width: 30px;
    height: 30px;
  }
  .dle .mat-checkbox-inner-container {
    height: 30px;
    width: 30px;
  }
  .user_add_button {
    justify-content: flex-start;
  }
  .card-header h6 {
    margin-left: 0px;
  }
  .my-ordr li.nav-item {
    width: 25%;
  }
  .my-ordr .driver_customer_details .nav-tabs .nav-link {
    font-size: 12px;
  }
  .my-ordr figure svg {
    width: 18px;
    height: 18px;
    margin: 0px;
  }
  .inner_content_tabbing.ng-star-inserted {
    width: 100%;
  }
  .main_admin_statusbutton button.btn.btn-primary {
    padding: 5px 10px;
    display: flex;
    width: 60px;
    height: 25px;
  }
  .main_admin_status button.btn.btn-danger {
    padding: 5px 10px;
    width: 60px;
    height: 25px;
  }
  .order_type {
    font-size: 10px;
    height: 23px;
    padding: 7px 14px;
  }
  .modal-content {
    width: 350px;
    height: auto;
  }

  .modal-body,
  modal-footer,
  .modal-header {
    padding: 10px;
  }

  .common-btn {
    font-size: 12px;
  }
  .form-group label {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .card_flex .card {
    display: flex;
  }
  .login_formss {
    height: 100%;
    align-items: flex-start;
  }
  .owner_field .input-group figure {
    right: 5px;
    top: 3px;
  }
  .time_slot .input-group figure svg {
    width: 10px;
  }
  .input-group i {
    transform: translate(-18%, 60%);
    font-size: 14px;
  }
  .usrevlist ul {
    width: 100%;
  }

  .card-header h6 {
    width: 100%;
    font-size: 18px;
  }
  .sub_rat .ussubs_rat h2 {
    font-size: 38px;
  }
  .sub_rat {
    margin: 10px 0 0 0;
  }
  .ralstl {
    margin-top: 25px;
  }
  .ussubs_rat h4 {
    color: black;
    font-size: 18px;
  }
  .usratlis .stl i {
    font-size: 12px;
    padding: 2px 5px;
  }
  .user_title .user_title_inner h6 {
    font-size: 20px;
  }
  .notflist ul {
    width: 100%;
  }
  .notflistit {
    font-size: 12px;
  }
  .notflistit h4 {
    font-size: 12px;
  }
  .ngx-pagination .disabled {
    padding: 7px !important;
  }
  .ngx-pagination a {
    padding: 5px !important;
  }

  .product_image {
    width: 90px;
    height: 90px;
  }
  .txt-lt a {
    font-size: 12px;
    font-weight: 500;
  }
  .avrat li span {
    width: 46%;
    font-size: 12px;
  }
  .rwlis h2 {
    font-size: 12px;
  }
  .datesrp {
    font-size: 12px;
  }
  .sub_rat h1 {
    font-size: 50px;
  }
  .ralstl i.fas {
    padding: 2px 8px;
  }
  .catbtn_ui {
    width: 100%;
    flex-wrap: wrap;
  }
  .card_flex .hvrcursor {
    width: 48%;
  }
  .my-dsbrd .hvrcursor .card-body {
    padding: 10px;
    height: 80px;
    border-radius: 6px;
  }
  .col-card figure svg {
    width: 25px;
    height: 25px;
  }

  .my-ordr .driver_customer_details ul.nav.nav-tabs:after {
    display: none;
  }
  .my-ordr .driver_customer_details .nav-tabs a.nav-link.active {
    font-size: 14px !important;
  }
  .upper_details_inner .user_img,
  .driver_details .user_img {
    width: 30px;
    height: 30px;
  }
  .driver_customer_details ul.nav.nav-tabs {
    justify-content: flex-start;
  }
  .my-dsbrd .h5.mb-0.dshbrd_dta {
    font-size: 12px;
  }
  .no-gutters .h5 {
    color: #fff;
    font-size: 12px;
  }
  .my-ordr .user_details a.call_nbumer {
    font-size: 10px;
  }
  .my-ordr figure {
    width: 20px;
    height: 20px;
    margin: 0px 4px 0px 0px;
  }
  .show_address h4 {
    font-size: 16px;
  }
  .show_address {
    font-size: 12px;
  }
  .catbtn_ui {
    justify-content: flex-start;
  }
  .day_time .items ul li {
    width: 100%;
    margin: 0px 8px 8px 0px;
  }
  .user_filter {
    width: 184px;
  }
  .col-card figure svg {
    width: 20px;
    height: 20px;
  }
  .my-ordr li.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  .main_admin_statusbutton button.btn.btn-primary {
    padding: 5px 10px;
    margin-right: 3px;
    width: 63px;
  }
  .main_admin_status button.btn.btn-danger {
    padding: 5px 10px;
    width: 63px;
  }

  .my-ordr .main_admin_details .gry {
    font-size: 12px;
  }
  .my-ordr .tabbing_top_heading h3 {
    font-size: 14px;
  }
  .order_type {
    font-size: 12px;
  }
  .my-ordr figure {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .card .h4 {
    font-size: 14px;
  }
  .card .h4 {
    font-size: 12px;
  }
  .col-card {
    height: auto;
    width: 50px;
  }
  .row {
    display: block;
  }
  .col-card {
    margin-bottom: 5px;
  }
  .col-card {
    justify-content: flex-start;
  }
}

.tym-slot-mn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  .slot-in {
    width: 10%;
    background: #fff;
    box-shadow: 0px 0px 2px 2px #00000005;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #0000001c;
    height: 3em;
    button {
      width: 100%;
      height: 100%;
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.form-control[readonly] {
  background: unset;
}

.otp-input {
  font-size: 20px !important;
}

button.btn.btn-primary {
  font-size: 14px;
}


.im_placeholder{
  figure{
    width: 230px;
    height: 200px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

    }
  }
}

.otp-input{
  width: 35px !important;
  height: 35px !important;
}

.lt-ft{
  width: 100%;
}
table{
  margin-bottom: 0;
  color: #212529;
  
  border-collapse: separate;
    border-spacing: 0 10px;

  td{
    font-size: 14px;
    padding: 5px;
  }
  th{
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    white-space: nowrap;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
thead th:first-child {
  border-top-left-radius: 20px;
}
thead th:last-child {
  border-top-left-radius: 20px;
}
}

thead{
  position: sticky;
  top: 0;
  tr {
    height: 60px;
    background: #c3c3c324;
}
}
@media (max-width:1400px) {
  table{
    td{
      font-size: 12px;
    }
    th{
      font-size: 14px;
    }
  }
}

::ng-deep{
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffcd00!important;
}
}

.avial_btn {
  margin-right: 20px;
}
.hedr_dropdown .form-control.mat-select .mat-select-placeholder,
.hedr_dropdown .form-control.mat-select .mat-select-arrow  {
  color:  #ffcd00 !important;
}
